import { useState } from "react";
import Axios from "axios";


function FindAMatch() {

    const [matchData, setMatchData] = useState([]);

    const getgirldata = () => {
        Axios.post("https://aajkaa-gsm.aajkaa.in/getgirldata",
        ).then((response) => {
            setMatchData(response.data);
        });
    };

    const getboydata = () => {
        Axios.post("https://aajkaa-gsm.aajkaa.in/getboydata",
        ).then((response) => {
            setMatchData(response.data);
        });
    };

    return (
        <>
            <div className="m-3">
                <button type="button" className="btn btn-primary m-1" onClick={getgirldata}>Look For Girl</button>
                <button type="button" className="btn btn-primary m-1" onClick={getboydata}>Look For Boy</button>
            </div>


            <div className="container">
                <div className="row">
                    {matchData.map((val, key) => {
                        return (
                            <>
                                <div className="col-md-3">
                                    <div className="card">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjYme2mXrfEjdxBNC3VvYC0uyfOKvXer2ItQ&usqp=CAU" className="card-img-top " alt="..." style={{filter: "blur(0px)"}}/>
                                        <div className="card-body">
                                            {/* <label className="card-title fs-5">Candidate Name : </label> */}
                                            <p className="card-text text-primary fs-5">{val.candidate_firstname}</p>
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"  key="{val.Cadidate_id}">
                                                Show Full Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>





                                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5 text-center" id="exampleModalLabel">Modal title</h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <input type="text" defaultValue={val.candidate_firstname} />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" className="btn btn-primary">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )

                    })}
                </div>
            </div>
        </>
    );
}

export default FindAMatch;
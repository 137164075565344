import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import heading from "../Photos/heading.png";
import apiClient from "../../api";
import axios from "axios";

function CheckApplicationStatus() {
  const history = useHistory();

  const [applicationStatus, setApplicationStatus] = useState([]);
  const [candidateID, setcandidateID] = useState([]);

  const getApplicationStatus = () => {
    apiClient.post("/applicationstatus", {
      candidateID1: candidateID,
    }).then((response) => {
      console.log(response.data)
      setApplicationStatus(response.data);
      if (response.data.length === 0) {
        setModalMessage('Enter a valid Applicant-ID');
        setShowModal(true);
      } else if (response.data[0].Status === "Approved") {
        setModalMessage(`Application of ${response.data[0].candidate_firstname} has been Approved. Candidate ID is ${candidateID}.`);
        setShowModal(true);
      } else if (response.data[0].Status === "Under Process" || response.data.Status === "Recommended") {
        setModalMessage('Your application is awating approval');
        setShowModal(true);
      } else if (response.data[0].Status === "In Complete") {
        setModalMessage('Your photos upload is pending');
        setShowModal(true);
      } else if (response.data[0].Status === "Disabled") {
        setModalMessage('Your Application is Disabled');
        setShowModal(true);
      } else if (response.data[0].Status === "Enabled") {
        setModalMessage('Your Application is Enabled');
        setShowModal(true);
      }
    });
  };

  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  const sendrequesttoreferenceagain = () => {
    axios.post("https://aajkaa-gsm.aajkaa.in/sendrequesttoreferenceagain", {
      candidateID: candidateID,
    }).then((response) => {
      setApplicationStatus(["temp"])
      if(response.data == 'Request already send today'){
        setShowModal(false);
        setModalMessage(response.data);
        setShowModal(true);
      } else if(response.data == 'Request sended to reference'){
        setShowModal(false);
        setModalMessage(response.data);
        setShowModal(true);
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }


  const closeModal = () => {
    setShowModal(false);
  };

  const [projectHeading, setProjectHeading] = useState([]);
  useEffect(() => {
    axios.post("https://aajkaa-gsm.aajkaa.in/getprojectheading").then((response) => {
      setProjectHeading(response.data);
    });
  }, [])


  return (
    <>
      <div className="mx-3">
        <form>
          <h1 className="text-primary col-md-5 text-center mt-2">
            {projectHeading.length > 0 ? projectHeading[0].projectheading :
              <>
                <div className="spinner-border fs-6" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            }
          </h1>
          <div className="col-md-5">
            <div className="">
              <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 mx-4 text-center" id="title" >Application Status</div>
              <div className="my-3 col-md-7 mx-4">
                <label className="form-label">Applicant Id</label>
                <input type="number" className="form-control" id="exampleInputEmail1" onChange={(event) => { setcandidateID(event.target.value); }} autoFocus autoComplete="off" />
              </div>
              <button type="button" style={{ marginLeft: "23px" }} className="btn btn-primary" onClick={getApplicationStatus} data-bs-toggle="modal" data-bs-target="#exampleModal" >Check</button>
              <Link onClick={gotodashboard} className="btn btn-primary mx-1">Back</Link>
              <Link className="btn btn-danger" to="/resendapplicantid">Forgot Applicant-ID</Link>
            </div>
          </div>
        </form>
      </div>

      {/* modal bootstrap */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">
                GSM says
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
            </div>
            <div className="modal-body text-primary"><b>{modalMessage}</b></div>
            <div className="modal-footer">
              {applicationStatus.map((val, key) => {
                return (
                  <>
                    {val.Status === "Under Process" || val.Status === "Recommended" ? (
                      <Link className="btn btn-success" onClick={sendrequesttoreferenceagain} to='/CheckApplicationStatus'>Send request to reference again</Link>
                    ) : null}

                    {val.Status === "Approved" ? (
                      <Link to="/match" className="btn btn-primary my-2" target="_parent" >Find A Match</Link>
                    ) : null}

                    {val.Status === "In Complete" ? (
                      <Link to="/EditApplicationForm" className="btn btn-primary my-2" target="_parent" >Modify Application</Link>
                    ) : null}
                  </>
                );
              })}
              {applicationStatus.length === 0 ? 
                <div><Link className="btn btn-danger mx-2" to="/resendapplicantid" target="_parent">Request Applicant ID</Link><Link onClick={gotodashboard} className="btn btn-secondary" data-bs-dismiss="modal">Ok</Link></div> 
                : <Link onClick={gotodashboard} className="btn btn-primary mx-1" data-bs-dismiss="modal">Back</Link>
              }
              {/* <Link onClick={gotodashboard} data-bs-dismiss="modal" type="button" className="btn btn-secondary" autoFocus>Ok</Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">GSM says</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {applicationStatus.length === 0 ? <div><b className="text-primary"><i className="bi bi-exclamation-triangle-fill text-warning mx-2"></i>Applicant ID incorrect</b></div> :

                applicationStatus.map((val, key) => {
                  if (val.Status === "Approved") {
                    return (
                      <div>
                        <b className="text-primary">Application of {val.candidate_firstname} has been Approved. Candidate ID is {candidateID}.</b>
                        <br />
                        <Link to="/match" className="btn btn-primary my-2" target="_parent" >Find A Match</Link>
                      </div>
                    );
                  }


                  else if (val.Status === "Under Process" || val.Status === "Recommended") {
                    return (
                      <div>
                        <b className="text-primary">Your application is awating approval</b>
                      </div>
                    );
                  }

                  else if (val.Status === "In Complete") {
                    return (
                      <div>
                        <b className="text-primary">Your photos upload is pending</b>
                        <br />
                        <Link to="/EditApplicationForm" className="btn btn-primary my-2" target="_parent" >Modify Application</Link>
                      </div>
                    );
                  }

                  else if (val.Status === "Disabled") {
                    return (
                      <div>
                        <b className="text-primary">Your Application is Disabled</b>
                      </div>
                    );
                  }

                  else if (val.Status === "Enabled") {
                    return (
                      <div>
                        <b className="text-primary">Your Application is Enabled</b>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="modal-footer">
              {applicationStatus.map((val, key) => {
                return (
                  (val.Status == "Under Process" || val.Status === "Recommended" ? <Link className="btn btn-success" onClick={sendrequesttoreferenceagain} to='/CheckApplicationStatus'>Send request to refernce again</Link> : <></>)
                )

              })}
              {
                applicationStatus.length === 0 ? <div><Link className="btn btn-danger mx-2" to="/resendapplicantid" target="_parent">Request Applicant ID</Link><Link onClick={gotodashboard} className="btn btn-secondary" data-bs-dismiss="modal">Ok</Link></div> :
                  <Link onClick={gotodashboard} className="btn btn-primary mx-1" data-bs-dismiss="modal">Back</Link>
              }
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default CheckApplicationStatus;

import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import pdf from '../Applicant/ApplicationFormPrintable221213.pdf'
import heading from "../Photos/heading.png";
import { useHistory } from "react-router-dom";
import axios from "axios";


function PrintApplicationForm() {

  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  const [projectHeading, setProjectHeading] = useState([]);
  useEffect(() => {
    axios.post("https://aajkaa-gsm.aajkaa.in/getprojectheading").then((response) => {
      setProjectHeading(response.data);
    });
  })

  const downloadPdf = () => {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdf; // Set the href attribute to the PDF file path
    link.download = 'Blank Application Form.pdf'; // Set the download attribute
    link.click(); // Simulate a click event to trigger the download
  };

  return (
    <>
      <h1 className="text-primary text-center mt-2 sticky-top">
        {projectHeading.length > 0 ? projectHeading[0].projectheading :
          <>
            <div className="spinner-border fs-6" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        }
      </h1>
      <div className='container'>
        <button className='btn btn-primary m-2' onClick={downloadPdf}>Download Blank Application Form</button>
        <Link onClick={gotodashboard} className='btn btn-primary'>Back</Link>
        <iframe className='border border-5 my-3' src={pdf} style={{ width: "100%", height: "550px" }} title="myFrame"></iframe>
      </div>
    </>
  )
}

export default PrintApplicationForm;
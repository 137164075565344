import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import image from "../Photos/image.png";
import heading from "../Photos/heading.png";
import "./AppButton.css";
import Axios from "axios";
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';

function ApplicantButton() {
  const location = useLocation();
  const history = useHistory();
  const verified = location.state && location.state.verified;
  const [projectHeading, setProjectHeading] = useState([]);

  if (!verified) {
    history.replace("/");
  }

  const pageId = 1

  useEffect(() => {
    Axios.post("https://aajkaa-gsm.aajkaa.in/getprojectheading").then((response) => {
      setProjectHeading(response.data);
    });
  }, []);


  return (
    <>
      <h1 className="text-primary text-center mt-2">
        {projectHeading.length > 0 ? projectHeading[0].projectheading :
          <>
            <div className="spinner-border fs-6" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        }
      </h1>
      <h2 className="text-primary text-center mt-2">WELCOME</h2>

      <div className="container-fluid" style={{ display: "flex", flexDirection: "row", height: "100%" }} >
        <div className="col-md-4 col-xs-12 p-4">
          <div className="row">
            <div className="col-md-12">

              {/* <div className="row">
                <div className="col btn btn-primary m-1 align-items-center" style={{ display: "flex", whiteSpace: "nowrap" }}>
                  <Link className="btn btn-primary" to="/ApplicantRegistration" style={{ flex: "1", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                    Fill Application
                  </Link>
                </div>
                <div className="col btn btn-primary m-1 align-items-center" style={{ display: "flex", whiteSpace: "nowrap" }} >
                  <Link className="btn btn-primary" to="/CheckApplicationStatus" style={{ flex: "1", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                    Application Status
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col btn btn-primary m-1 align-items-center" style={{ display: "flex", whiteSpace: "nowrap" }} >
                  <Link className="btn btn-primary" to="/printApplicationform" style={{ flex: "1", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                    Print Application
                  </Link>
                </div>
                <div className="col btn btn-primary m-1 align-items-center" style={{ display: "flex", whiteSpace: "nowrap" }} >
                  <Link className="btn btn-primary" to="/EditApplicationForm" style={{ flex: "1", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                    Modify Application
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col btn btn-primary m-1 align-items-center" style={{ display: "flex", whiteSpace: "nowrap" }} >
                  <Link className="btn btn-primary" to="/resendapplicantid" style={{ flex: "1", fontSize: "clamp(14px, 3vw, 24px)" }} >
                    Request Applicant ID
                  </Link>
                </div>
              </div> */}
              <div className="row mb-2 gap-2">
                <Link className="col btn btn-primary" to="/ApplicantRegistration" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                  Fill Application
                </Link>
                <Link className="col btn btn-primary" to="/CheckApplicationStatus" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                  Application Status
                </Link>
              </div>
              <div className="row mb-2 gap-2">
                <Link className="col btn btn-primary" to="/printApplicationform" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                  Print Application
                </Link>
                <Link className="col btn btn-primary" to="/EditApplicationForm" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                  Modify Application
                </Link>
              </div>
              <div className="row gap-2">
                <Link className="col btn btn-primary" to="/resendapplicantid" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} >
                  Request Applicant ID
                </Link>
              </div>
            </div>
          </div>

          <div className="row mt-5 largeOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>


        </div>
        <div className="col-md-8 col-sm-12 p-4 ">
          <div className="row mb-3">
            <div className="col-md-12 text-primary" style={{ fontSize: "clamp(20px, 1.3vw, 1vw)" }}>
              <h3>User Instructions:</h3>
              <ul>
                <li>
                  To get married, click on <b>[Fill Application] </b> button.
                </li>
                <li>
                  To be able to fill the application successfully, you need to have
                  <ul>
                    <li>A valid working accessible Whatsapp number.</li>
                    <li>
                      Two photos of the applicant: <br />one Profile Photo<b className="text-danger">(file size
                        &lt; 250KB)</b> & one Full Photo<b className="text-danger">(file size &lt; 250KB)</b>.
                      <br /><b className="text-danger">Photos upload is compulsory.</b>
                    </li>
                    <li>
                      A valid working Whatsapp number of a <b><u>reference person</u></b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Enter your Whatsapp number and get it validated thru an OTP.
                </li>
                <li>
                  On successful validation of your Whatsapp number, fill the application form.{" "}
                </li>
                <li>
                  {" "}
                  After submitting the application form
                  <br />
                  you receive Applicant-Id on your verified Whatsapp number. <br />
                  you have to upload 2 photos
                </li>
                <li>
                  After photos upload, the reference person is
                  informed & asked for confirmation.
                </li>
                <li>
                  When the reference person replies with a "yes" followed by your
                  applicant-Id,
                  <ul>
                    <li>
                      Your profile(application form) will be published(visible to
                      other candidates).
                    </li>
                    <li>
                      Your Applicant-Id becomes Candidate-Id & you receive your password.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5 mb-5 smallOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <footer className="fs-6 fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '13px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer>
    </>
  );
}

export default ApplicantButton;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from '../Photos/khanda.png';
import heading from "../Photos/heading.png";



function EditProfile() {
    const [CandidateID, setCandidateID] = useState("");

    // const [userInfo, setUserInfo] = useState({
    //     file: [],
    //     filePreview: null,
    // });

    // const handleInputChange = (event) => {
    //     const uploadedFile = event.target.files[0];
    //     setUserInfo({
    //         ...userInfo,
    //         file: uploadedFile,
    //         filePreview: URL.createObjectURL(uploadedFile),
    //     });
    // };

    // const [userInfo2, setuserInfo2] = useState({
    //     file: [],
    //     filepreview: null,
    // });

    // const handleInputChange2 = (event) => {
    //     setuserInfo2({
    //         ...userInfo2,
    //         file: event.target.files[0],
    //         filepreview: URL.createObjectURL(event.target.files[0]),
    //     });
    // };
    // console.log(typeof (userInfo.file));


    // const [candidateInfo, setCandidateInfo] = useState("");
    // const [candidatePhoto, setCandidatePhoto] = useState("");
    const [CandidateFullName, setCandidateFullName] = useState("");
    const [candidatelastName, setCandidatelastName] = useState("");
    const [candidateSurname, setCandidateSurname] = useState("");
    const [CandidateCurrentAddress, setCandidateCurrentAddress] = useState("");
    const [DateOfBirth, setDateOfBirth] = useState("");
    const [MaritalStatus, setMaritalStatus] = useState("");
    const [Religion, setReligion] = useState("");
    const [Gender, setGender] = useState("");
    const [Height, setHeight] = useState("");
    const [Weight, setWeight] = useState("");
    const [AnyDisability, setAnyDisability] = useState("");
    const [MotherTonge, setMotherTonge] = useState("");
    const [LanguagesKnown, setLanguagesKnown] = useState("");
    const [Nationality, setNationality] = useState("");
    const [WhatsappNumber, setWhatsappNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [qulification, setQulification] = useState("");
    const [degree, setDegree] = useState("");
    const [HighestEducation, setHighestEducation] = useState("");
    const [CandidateOccupation, setCandidateOccupation] = useState("");
    const [NatureOfWork, setNatureOfWork] = useState("");
    const [Currency, setcurrency] = useState("");
    const [CandidateAnnualIncome, setCandidateAnnualIncome] = useState("");
    const [FatherAddress, setFatherAddress] = useState("");
    const [FamilyAnnualIncome, setFamilyAnnualIncome] = useState("");
    const [GurudwaraVisited, setGurudwaraVisited] = useState("");
    const [GcontactPerson, setGcontactPerson] = useState("");
    const [GcontactNumber, setGcontactNumber] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [fatherAge, setFatherAge] = useState("");
    const [fatherEducation, setFatherEducation] = useState("");
    const [fatherDegree, setFatherDegree] = useState("");
    const [mothername, setMotherName] = useState("");
    const [motherage, setMotherAge] = useState("");
    const [motherEducation, setMotherEducation] = useState("");
    const [motherDegree, setMotherDegree] = useState("");
    const [relative3, setRelative3] = useState("");
    const [relative3name, setRelative3Name] = useState("");
    const [relative3age, setRelative3Age] = useState("");
    const [relative3Education, setRelative3Education] = useState("");
    const [relative3Degree, setRelative3Degree] = useState("");
    const [relative4, setRelative4] = useState("");
    const [relative4name, setRelative4Name] = useState("");
    const [relative4age, setRelative4Age] = useState("");
    const [relative4Education, setRelative4Education] = useState("");
    const [relative4Degree, setRelative4Degree] = useState("");
    const [relative5, setRelative5] = useState("");
    const [relative5name, setRelative5Name] = useState("");
    const [relative5age, setRelative5Age] = useState("");
    const [relative5Education, setRelative5Education] = useState("");
    const [relative5Degree, setRelative5Degree] = useState("");
    const [relative6, setRelative6] = useState("");
    const [relative6name, setRelative6Name] = useState("");
    const [relative6age, setRelative6Age] = useState("");
    const [relative6Education, setRelative6Education] = useState("");
    const [relative6Degree, setRelative6Degree] = useState("");
    const [relative7, setRelative7] = useState("");
    const [relative7name, setRelative7Name] = useState("");
    const [relative7age, setRelative7Age] = useState("");
    const [relative7Education, setRelative7Education] = useState("");
    const [relative7Degree, setRelative7Degree] = useState("");
    const [remarks, setRemarks] = useState("");
    const [referenceName, setReferenceName] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    // console.log(fatherName,mothername);

    const candidate_id = localStorage.getItem("candidate_id");

    useEffect(() => {
        approveapplicant();
    }, []);

    const approveapplicant = () => {
        Axios.post("https://aajkaa-gsm.aajkaa.in/recommendapplicant", {
            CandidateID: candidate_id,
        }).then((response) => {
            // setCandidatePhoto(response.data.map((val, key) => val.Candidate_Photo))
            setCandidateFullName(response.data.map((val, key) => val.candidate_firstname));
            setCandidatelastName(response.data.map((val, key) => val.candidate_middlename));
            setCandidateSurname(response.data.map((val, key) => val.candidate_lastname));
            setCandidateCurrentAddress(response.data.map((val, key) => val.Candidate_Current_Address));
            // setDateOfBirth(new Date(response.data.map((val, key) => val.Date_of_Birth)).toISOString().substring(0, 10));
            setDateOfBirth(response.data.map((val, key) => { const date = new Date(val.Date_of_Birth); return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); }));
            setMaritalStatus(response.data.map((val, key) => val.Marital_Status));
            setReligion(response.data.map((val, key) => val.Religion));
            setGender(response.data.map((val, key) => val.Gender));
            setHeight(response.data.map((val, key) => val.height_cm));
            setWeight(response.data.map((val, key) => val.Weight));
            setAnyDisability(response.data.map((val, key) => val.Any_Disability));
            setMotherTonge(response.data.map((val, key) => val.Mother_Tongue));
            setLanguagesKnown(response.data.map((val, key) => val.Languages_Known));
            setNationality(response.data.map((val, key) => val.Nationality));
            setWhatsappNumber(response.data.map((val, key) => val.WhatsApp_Number));
            setEmail(response.data.map((val, key) => val.Email_ID));
            setQulification(response.data.map((val, key) => val.Qualification));
            setDegree(response.data.map((val, key) => val.Qualification));
            setHighestEducation(response.data.map((val, key) => val.Degree));
            setCandidateOccupation(response.data.map((val, key) => val.Candidate_Occupation));
            setNatureOfWork(response.data.map((val, key) => val.Nature_Of_Work));
            setcurrency(response.data.map((val, key) => val.Currency));
            setCandidateAnnualIncome(response.data.map((val, key) => val.Candidate_Annual_Income));
            setFatherAddress(response.data.map((val, key) => val.family_permanent_address));
            setFamilyAnnualIncome(response.data.map((val, key) => val.Family_Annual_Income));
            setGurudwaraVisited(response.data.map((val, key) => val.Gurudwara_Normally_visited));
            setGcontactPerson(response.data.map((val, key) => val.G_Contact_Person));
            setGcontactNumber(response.data.map((val, key) => val.G_Phone_Number));
            setRemarks(response.data.map((val, key) => val.additional_information));
            setReferenceName(response.data.map((val, key) => val.Reference_Name));
            setReferenceNumber(response.data.map((val, key) => val.Reference_Contact.substring(2)));
            // console.log(response.data.map((val, key) => val.Candidate_Photo));


            Axios.post("https://aajkaa-gsm.aajkaa.in/familydata", {
                CandidateID: candidate_id,
            }).then((response) => {
                setFatherName((response.data.map((val, key) => val.Relative_name))[0]);
                setFatherAge((response.data.map((val, key) => val.Relative_age))[0]);
                setFatherEducation((response.data.map((val, key) => val.Relative_Education))[0]);
                setFatherDegree((response.data.map((val, key) => val.Relative_Degree))[0]);
                setMotherName((response.data.map((val, key) => val.Relative_name))[1]);
                setMotherAge((response.data.map((val, key) => val.Relative_age))[1]);
                setMotherEducation((response.data.map((val, key) => val.Relative_Education))[1]);
                setMotherDegree((response.data.map((val, key) => val.Relative_Degree))[1]);
                setRelative3((response.data.map((val, key) => val.Relationship))[2]);
                setRelative3Name((response.data.map((val, key) => val.Relative_name))[2]);
                setRelative3Age((response.data.map((val, key) => val.Relative_age))[2]);
                setRelative3Education((response.data.map((val, key) => val.Relative_Education))[2]);
                setRelative3Degree((response.data.map((val, key) => val.Relative_Degree))[2]);
                setRelative4((response.data.map((val, key) => val.Relationship))[3]);
                setRelative4Name((response.data.map((val, key) => val.Relative_name))[3]);
                setRelative4Age((response.data.map((val, key) => val.Relative_age))[3]);
                setRelative4Education((response.data.map((val, key) => val.Relative_Education))[3]);
                setRelative4Degree((response.data.map((val, key) => val.Relative_Degree))[3]);
                setRelative5((response.data.map((val, key) => val.Relationship))[4]);
                setRelative5Name((response.data.map((val, key) => val.Relative_name))[4]);
                setRelative5Age((response.data.map((val, key) => val.Relative_age))[4]);
                setRelative5Education((response.data.map((val, key) => val.Relative_Education))[4]);
                setRelative5Degree((response.data.map((val, key) => val.Relative_Degree))[4]);
                setRelative6((response.data.map((val, key) => val.Relationship))[5]);
                setRelative6Name((response.data.map((val, key) => val.Relative_name))[5]);
                setRelative6Age((response.data.map((val, key) => val.Relative_age))[5]);
                setRelative6Education((response.data.map((val, key) => val.Relative_Education))[5]);
                setRelative6Degree((response.data.map((val, key) => val.Relative_Degree))[5]);
                setRelative7((response.data.map((val, key) => val.Relationship))[6]);
                setRelative7Name((response.data.map((val, key) => val.Relative_name))[6]);
                setRelative7Age((response.data.map((val, key) => val.Relative_age))[6]);
                setRelative7Education((response.data.map((val, key) => val.Relative_Education))[6]);
                setRelative7Degree((response.data.map((val, key) => val.Relative_Degree))[6]);
                // console.log("valueset" , (response.data.map((val, key) => val.))[1]);
            });

        });
    };

    const saveapplicant = async () => {

        // const formdata = new FormData();
        // const formdata1 = new FormData();
        // // const formdata2 = new FormData();

        // formdata.append("CandidateID", candidate_id);
        // formdata.append("avatar", userInfo.file);

        // formdata1.append("CandidateID", candidate_id);
        // formdata1.append("avatar2", userInfo2.file);

        // formdata2.append("CandidateID", candidate_id);
        // formdata2.append("CandidateCurrentAddress", CandidateCurrentAddress);
        // formdata2.append("Height", Height);
        // formdata2.append("Weight", Weight);
        // formdata2.append("AnyDisability", AnyDisability);
        // formdata2.append("MotherTonge", MotherTonge);
        // formdata2.append("LanguagesKnown", LanguagesKnown);
        // formdata2.append("Nationality", Nationality);
        // formdata2.append("Email", Email);
        // formdata2.append("qulification", qulification);
        // formdata2.append("degree", degree);
        // formdata2.append("CandidateOccupation", CandidateOccupation);
        // formdata2.append("NatureOfWork", NatureOfWork);
        // formdata2.append("Currency", Currency);
        // formdata2.append("CandidateAnnualIncome", CandidateAnnualIncome);
        // formdata2.append("FamilyAnnualIncome", FamilyAnnualIncome);
        // formdata2.append("GurudwaraVisited", GurudwaraVisited);
        // formdata2.append("GcontactPerson", GcontactPerson);
        // formdata2.append("GcontactNumber", GcontactNumber);
        // formdata2.append("remarks", remarks);
        // formdata2.append("HighestEducation", HighestEducation);


        if (true) {
            Axios.post("https://aajkaa-gsm.aajkaa.in/updateotherthings12", {
                CandidateID: candidate_id,
                CandidateCurrentAddress: CandidateCurrentAddress,
                Height: Height,
                Weight: Weight,
                AnyDisability: AnyDisability,
                MotherTonge: MotherTonge,
                LanguagesKnown: LanguagesKnown,
                Nationality: Nationality,
                Email: Email,
                qulification: qulification,
                degree: degree,
                CandidateOccupation: CandidateOccupation,
                NatureOfWork: NatureOfWork,
                Currency: Currency,
                CandidateAnnualIncome: CandidateAnnualIncome,
                FamilyAnnualIncome: FamilyAnnualIncome,
                GurudwaraVisited: GurudwaraVisited,
                GcontactPerson: GcontactPerson,
                GcontactNumber: GcontactNumber,
                remarks: remarks,
                HighestEducation:HighestEducation,
            })
        }
        // if (userInfo.file && userInfo.file.name != undefined) {
        //     if (userInfo2.file && userInfo2.file.name != undefined) {
        //         Axios.post("https://aajkaa-gsm.aajkaa.in/updatewithphoto", formdata, {
        //             headers: { "Content-Type": "multipart/form-data" },
        //         }).then(
        //             Axios.post("https://aajkaa-gsm.aajkaa.in/updatewithphoto2", formdata1, {
        //                 headers: { "Content-Type": "multipart/form-data" },
        //             })
        //         );
        //     } else {
        //         Axios.post("https://aajkaa-gsm.aajkaa.in/updatewithphoto", formdata, {
        //             headers: { "Content-Type": "multipart/form-data" },
        //         })
        //     }
        // } else {
        //     if (userInfo2.file && userInfo2.file.name != undefined) {
        //         Axios.post("https://aajkaa-gsm.aajkaa.in/updatewithphoto2", formdata1, {
        //             headers: { "Content-Type": "multipart/form-data" },
        //         })
        //     } else {

        //     }
        // }
    };

    return (
        <div className="container">
            <div className="mt-2 text-primary text-center sticky-top" style={{ "backgroundColor": "white" }}>
                <img src={heading} alt="" class="mx-2 col-md-5 text-center img-fluid" />
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">GSM says</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <i class="bi bi-exclamation-triangle-fill text-warning mx-2"></i>Are you sure
                        </div>
                        <div class="modal-footer">
                            <Link to="/match" class="btn btn-primary" target="_parent" onClick={saveapplicant}>Yes</Link>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-3 h4 text-primary" id="heading">
                <h3 className="text-primary text-center">Modify Form</h3>
            </div>
            <div className="m-3">

                <form onLoad="genCandidateID" className="row g-3" action="" method="post" id="formform" >
                    <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3">
                        Application Form
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label fs-5">Applicant's First Name</label>
                        <input className="form-control" type="text" value={CandidateFullName} onChange={(event) => { setCandidateFullName(event.target.value); }} disabled />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label fs-5">Applicant's Middle Name</label>
                        <input className="form-control" type="text" value={candidatelastName} onChange={(event) => { setCandidatelastName(event.target.value) }} disabled />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label fs-5">Applicant's Last Name</label>
                        <input className="form-control" type="text" value={candidateSurname} onChange={(event) => { setCandidateSurname(event.target.value) }} disabled />
                    </div>
                    {/* <div className=" col-md-6 ">
                        <label htmlFor="formFileMultiple" className="form-label fs-5">Upload Applicant's Profile Photo</label>
                        <input className="form-control" type="file" onChange={handleInputChange} />
                    </div>
                    <div className=" col-md-6 ">
                        <label htmlFor="formFileMultiple" className="form-label fs-5">Upload Applicant's Full Photo</label>
                        <input className="form-control" type="file" onChange={handleInputChange2} />
                    </div> */}

                    <div className="col-md-12">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Applicant's Current Address</label>
                        <input className="form-control" type="text" value={CandidateCurrentAddress} onChange={(event) => { setCandidateCurrentAddress(event.target.value) }} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Date of Birth</label>
                        <input className="form-control" type="text" value={DateOfBirth} onChange={(event) => { setDateOfBirth(event.target.value) }} disabled />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom04" className="form-label fs-5">Marital Status</label>
                        <select className="form-select" onChange={(event) => { setMaritalStatus(event.target.value) }} disabled>
                            <option value="DEFAULT" selected disabled>{MaritalStatus}</option>
                            <option>Never married</option>
                            <option>Divorcee</option>
                            <option>Widow/Widower</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom04" className="form-label fs-5">Religion</label>
                        <select className="form-select" onChange={(event) => { setReligion(event.target.value) }} disabled>
                            <option value="DEFAULT" selected disabled>{Religion}</option>
                            <option>Sikh (Keshdhari)</option>
                            <option>Sikh (Amritdhari)</option>
                            <option>Sikh</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom04" className="form-label fs-5">Gender</label>
                        <select className="form-select" onChange={(event) => { setGender(event.target.value) }} disabled>
                            <option value="DEFAULT" selected disabled>{Gender}</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustomUsername" className="form-label fs-5" >Height</label>
                        <div className="input-group has-validation">
                            <span className="input-group-text" id="inputGroupPrepend">cm</span>
                            <input type="number" className="form-control" aria-describedby="inputGroupPrepend" value={Height} onChange={(event) => { setHeight(event.target.value) }} />
                            <div className="invalid-feedback">Please choose a username.</div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="validationCustomUsername" className="form-label fs-5" >Weight</label>
                        <div className="input-group has-validation">
                            <span className="input-group-text" id="inputGroupPrepend">kg</span>
                            <input type="number" className="form-control" aria-describedby="inputGroupPrepend" value={Weight} onChange={(event) => { setWeight(event.target.value) }} />
                            <div className="invalid-feedback">Please choose a username.</div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label fs-5">Any Disability?</label>
                        <input className="form-control" type="text" value={AnyDisability} onChange={(event) => { setAnyDisability(event.target.value) }} />
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Mother Tongue</label>
                        <input className="form-control" type="text" value={MotherTonge} onChange={(event) => { setMotherTonge(event.target.value) }} />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Languages Known</label>
                        <input className="form-control" type="text" value={LanguagesKnown} onChange={(event) => { setLanguagesKnown(event.target.value) }} />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Nationality</label>
                        <input className="form-control" type="text" value={Nationality} onChange={(event) => { setNationality(event.target.value) }} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label fs-5">Verified Phone Number</label>
                        <input className="form-control" type="number" disabled value={WhatsappNumber} onChange={(event) => { setWhatsappNumber(event.target.value) }} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Email-ID</label>
                        <div className="input-group has-validation">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                            <input className="form-control" type="email" aria-describedby="inputGroupPrepend" value={Email} onChange={(event) => { setEmail(event.target.value) }} />
                            <div className="invalid-feedback">Please choose a username.</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom04" className="form-label fs-5">Education</label>
                        <select className="form-select" onChange={(event) => { setQulification(event.target.value) }}>
                            <option value="DEFAULT" selected disabled>{qulification}</option>
                            <option>Under Graduate</option>
                            <option>Graduate</option>
                            <option>Post Graduate</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Highest Degree</label>
                        <input className="form-control" type="text" value={HighestEducation} onChange={(event) => { setHighestEducation(event.target.value) }} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Applicant's Occupation</label>
                        <select className="form-select" onChange={(event) => { setCandidateOccupation(event.target.value) }}>
                            <option value="DEFAULT" selected disabled>{CandidateOccupation}</option>
                            <option>Businessman</option>
                            <option>Professional</option>
                            <option>Govt. Service</option>
                            <option>Private Service</option>
                            <option>Serving Abroad</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label fs-5">Nature Of Work</label>
                        <select className="form-select" onChange={(event) => { setNatureOfWork(event.target.value) }}>
                            <option value="DEFAULT" selected disabled>{NatureOfWork}</option>
                            <option>IT</option>
                            <option>Medical</option>
                            <option>Engineering</option>
                            <option>Other</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="validationCustomUsername" className="form-label fs-5" >Currency</label>
                        <select className="form-select" onChange={(event) => { setcurrency(event.target.value) }}>
                            <option value="DEFAULT" selected disabled>{Currency}</option>
                            <option>INR - ₹</option>
                            <option>AUD - $</option>
                            <option>CAD - $</option>
                            <option>LKR - ₨</option>
                            <option>ARS - $</option>
                            <option>BDT - ৳</option>
                            <option>BTN - Nu.</option>
                            <option>AFN - ؋</option>
                            <option>BRL - R$</option>
                            <option>KHR - ៛</option>
                            <option>CNY - ¥</option>
                            <option>COP - $</option>
                            <option>DKK - kr</option>
                            <option>EGP - £</option>
                            <option>HKD - $</option>
                            <option>IDR - Rp</option>
                            <option>IRR - ﷼</option>
                            <option>ILS - ₪</option>
                            <option>JPY - ¥</option>
                            <option>JEP - £</option>
                            <option>KPW - ₩</option>
                            <option>KRW - ₩</option>
                            <option>LRD - $</option>
                            <option>MYR - RM</option>
                            <option>MXN - $</option>
                            <option>NPR - ₨</option>
                            <option>NGN - ₦</option>
                            <option>NOK - kr</option>
                            <option>OMR - ﷼</option>
                            <option>PKR - ₨</option>
                            <option>PHP - ₱</option>
                            <option>PLN - zł</option>
                            <option>QAR - ﷼</option>
                            <option>RUB - ₽</option>
                            <option>SAR - ﷼</option>
                            <option>RSD - Дин.</option>
                            <option>SGD - $</option>
                            <option>ZAR - R</option>
                            <option>SEK - kr</option>
                            <option>CHF - CHF</option>
                            <option>TWD - NT$</option>
                            <option>THB - ฿</option>
                            <option>UAH - ₴</option>
                            <option>GBP - £</option>
                            <option>YER - ﷼</option>
                            <option>ZWD - Z$</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustomUsername" className="form-label fs-5" >Applicant's Annual Income</label>
                        <div className="input-group has-validation">
                            <input type="number" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" value={CandidateAnnualIncome} onChange={(event) => { setCandidateAnnualIncome(event.target.value) }} />
                            <div className="invalid-feedback">Please choose a username.</div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">Family & Relatives</div>

                        <div className="row g-3">
                            <div className="col-md-2">
                                <input className="form-control" type="text" value="Father" disabled />
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={fatherName} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={fatherAge} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={fatherEducation} disabled>
                                    <option selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={fatherDegree} disabled >
                                    <option selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-2">
                                <input className="form-control" type="text" value="Mother" disabled />
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={mothername} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={motherage} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={motherEducation} disabled >
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={motherDegree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-2">
                                <select className="form-select" value={relative3} disabled>
                                    <option value="DEFAULT" selected disabled>Brother / Sister</option>
                                    <option>Brother</option>
                                    <option>Sister</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={relative3name} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={relative3age} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={relative3Education} disabled>
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={relative3Degree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-2">
                                <select className="form-select" value={relative4} disabled>
                                    <option value="DEFAULT" selected disabled>
                                        Brother / Sister
                                    </option>
                                    <option>Brother</option>
                                    <option>Sister</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={relative4name} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={relative4age} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={relative4Education} disabled>
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={relative4Degree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-2">
                                <select className="form-select" value={relative5} disabled>
                                    <option value="DEFAULT" selected disabled>Brother / Sister</option>
                                    <option>Brother</option>
                                    <option>Sister</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={relative5name} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={relative5age} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={relative5Education} disabled>
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={relative5Degree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-2">
                                <select className="form-select" value={relative6} disabled >
                                    <option value="DEFAULT" selected disabled>Brother / Sister</option>
                                    <option>Brother</option>
                                    <option>Sister</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                                <input className="form-control" type="text" value={relative6name} disabled />
                            </div>

                            <div className="col-md-2">
                                <input className="form-control" type="number" value={relative6age} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={relative6Education} disabled>
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={relative6Degree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />


                            <div className="col-md-2">
                                <select className="form-select" value={relative7} disabled>
                                    <option value="DEFAULT" selected disabled> Brother / Sister </option>
                                    <option>Brother</option>
                                    <option>Sister</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <input className="form-control" type="text" value={relative7name} disabled />
                            </div>
                            <div className="col-md-2">
                                <input className="form-control" type="number" value={relative7age} disabled />
                            </div>
                            <div className="col-md-2">
                                <select className="form-select" value={relative7Education} disabled>
                                    <option value="DEFAULT" selected disabled>Education</option>
                                    <option>Under Graduate</option>
                                    <option>Graduate</option>
                                    <option>Post Graduate</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={relative7Degree} disabled>
                                    <option value="DEFAULT" selected disabled>Occupation</option>
                                    <option>Businessman</option>
                                    <option>Professional</option>
                                    <option>Govt. Service</option>
                                    <option>Private Service</option>
                                    <option>Serving Abroad</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <hr className="d-md-none" />

                            <div className="col-md-9">
                                <label htmlFor="validationCustom02" className="form-label fs-5">Father's / Family's Permanent Address</label>
                                <input className="form-control" type="text" value={FatherAddress} onChange={(event) => { setFatherAddress(event.target.value) }} disabled />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustomUsername" className="form-label fs-5" >Family's Annual Income</label>
                                <div className="input-group has-validation">
                                    <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                    <input type="number" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" value={FamilyAnnualIncome} onChange={(event) => { setFamilyAnnualIncome(event.target.value) }} />
                                    <div className="invalid-feedback">Please choose a username.</div>
                                </div>
                            </div>

                            <div className="h4 pb-2 mb-1 text-primary border-bottom border-primary">Gurudwara Details</div>

                            <div className="col-md-6">
                                <label htmlFor="validationCustom02" className="form-label ">Gurudwara Normally visited : Name & Address</label>
                                <input className="form-control" type="text" value={GurudwaraVisited} onChange={(event) => { setGurudwaraVisited(event.target.value) }} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom02" className="form-label ">Name of Contact Person at Gurudwara</label>
                                <input className="form-control" type="text" value={GcontactPerson} onChange={(event) => { setGcontactPerson(event.target.value) }} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom02" className="form-label ">Phone no. of contact person</label>
                                <input className="form-control" type="number" value={GcontactNumber} onChange={(event) => { setGcontactNumber(event.target.value) }} />
                            </div>

                            <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">Any Other Information</div>

                            <div className="col-md-12">
                                <textarea class="form-control" value={remarks} onChange={(event) => { setRemarks(event.target.value) }}></textarea>
                            </div>

                            <div class="container text-primary border-bottom border-primary mt-3">
                                <div class="row">
                                    <div class="col-5 h4 d-flex align-items-center">
                                        <p class="mb-0">Reference details:</p>
                                    </div>

                                </div>
                            </div>



                            <div className="col-md-3">
                                <label htmlFor="validationCustom02" className="form-label">Reference Person Name</label>
                                <input className="form-control" type="text" value={referenceName} onChange={(event) => { setReferenceName(event.target.value); }} disabled />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom02" className="form-label">Reference WhatsApp Number</label>
                                <input className="form-control" type="number" value={referenceNumber} onChange={(event) => { setReferenceNumber(event.target.value); }} disabled />
                            </div>
                        </div>
                    </div>

                    <Link to="/EditApplicationForm" class="mx-3 mt-5 col-2 p-2 btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#exampleModal">Submit Form</Link>
                    <Link className='mx-3 mt-5 col-3 btn btn-primary mb-3 p-2'  to="/match"><i class="bi bi-caret-left-fill mx-2"></i> Go back without saving</Link>

                </form>
            </div>
        </div>
    );
}

export default EditProfile;
import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from "../Photos/khanda.png";
import couple from "../Photos/couple.jpg";
import apiClient from "../../api";
import heading from "../Photos/heading.png";



function ApplicantApproval() {
  const [users, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [relative1Info, setRelative1Info] = useState([]);
  const [candidateIDFilter, setCandidateIDFilter] = useState("*");
  const [wNumberFilter, setWNumberFilter] = useState("*");
  // const [photo, setPhoto] = useState([]);
  // console.log(
  //   "candidateIDFilter=",
  //   candidateIDFilter,
  //   " wNumberFilter=",
  //   wNumberFilter
  // );
  const searchCandidate = async () => {
    const response = await apiClient.post("/getsearchapprover", {
      candidateIDFilter: candidateIDFilter,
      wNumberFilter: wNumberFilter,
    });
    // const response = await Axios.post("https://aajkaa-gsm.aajkaa.in/getsearchboy",{
    //     Qualification:qualificationoffil,
    // });
    setUser(response.data);
  };

  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the scroll-to-top behavior
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // searchCandidate();
    getUsers();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const getUsers = async (e) => {
    const response = await apiClient.post("/getunapproveddata");
    setUser(response.data);
    console.log(response.data);
    // Axios.post("https://aajkaa-gsm.aajkaa.in/getdata1", {
    // }).then((response) => {
    //     setPhoto(response.data);
    //     photo.map((val, key) => { console.log(val.Candidate_Photo) })
    // });
  };

  const getCandidateImformation = (valueRecivedFromShowFullProfile) => {
    setData([]);
    setRelative1Info([]);
    Axios.post("https://aajkaa-gsm.aajkaa.in/getcandidateimformation", {
      CandidateID: valueRecivedFromShowFullProfile,
    }).then((response) => {
      setData(response.data);
    });
    Axios.post("https://aajkaa-gsm.aajkaa.in/recommendapplicantrelation1", {
      CandidateID: valueRecivedFromShowFullProfile,
    }).then((response) => {
      setRelative1Info(response.data);
    });
  };

  const approverName = localStorage.getItem("candidate_name");
  const approverid = localStorage.getItem("candidate_id");

  const ApproveApplicant = (valueRecivedFromShowFullProfile, CandidateWhatsAppNumber,gender,name) => {
    console.log(CandidateWhatsAppNumber);
    Axios.post("https://aajkaa-gsm.aajkaa.in/approveapplicant", {
      CandidateID: valueRecivedFromShowFullProfile,
      approverName: approverName,
      approverid:approverid,
    }).then(
      Axios.post("https://aajkaa-gsm.aajkaa.in/sendpassword", {
        CandidateID: valueRecivedFromShowFullProfile,
        CandidateNumber: CandidateWhatsAppNumber,
        gender:gender,
        name:name,
      })
    )
  };

  // const [cmValue, setCmValue] = useState(0);
  // const [feet, setFeetValue] = useState("");
  // const cmToFeet = (cm) => {
  //   const totalInches = Math.round(cm / 2.54);
  //   const feet = Math.floor(totalInches / 12);
  //   const inches = totalInches % 12;
  //   return `${feet}' ${inches}"`;
  // };

  // const handleCmInputChange = (e) => {
  //   const value = e.target.value;
  //   console.log(value);
  //   setCmValue(value);
  //   setFeetValue(cmToFeet(value));
  // };


  return (
    <>
      <div className="container">
        {/* <Link className='btn btn-primary text-center' to="/ApproverButton"><i class="bi bi-caret-left-fill"></i> Home</Link> */}

        <div className="row">
          <div className="mt-3 mb-2 text-primary text-center sticky-top" style={{ "backgroundColor": "white" }}>
            <img src={heading} alt="" class="mx-2 col-md-5 text-center img-fluid" />
          </div>
          <h2 className="text-primary">Following applicants pending for approval</h2>

          <div class="row align-items-center mb-3">
            <div class="col-12 col-md-auto">
              <Link className="btn btn-primary" to="/ApproverButton"><i class="bi bi-caret-left-fill"></i> Back</Link>
            </div>
            <div class="col-12 col-md-auto">
              <label for="inputPassword6" class="col-form-label">Candidate ID</label>
            </div>
            <div class="col-12 col-md-auto">
              <input type="number" placeholder="ID" class="form-control" onChange={(event) => { setCandidateIDFilter(event.target.value); }} />
            </div>
            <div class="col-12 col-md-auto d-none d-md-block">
              <span class="fs-2 border border-2 border-primary" style={{ height: "35px" }}></span>
            </div>
            <div class="col-12 col-md-auto">
              <label for="inputPassword6" class="col-form-label">Whatsapp Number</label>
            </div>
            <div class="col-12 col-md-2">
              <input type="number" class="form-control" placeholder="Number" onChange={(event) => { setWNumberFilter(event.target.value); }} />
            </div>
            <div class="col-12 col-md-1 mt-2">
              <Link className="btn btn-primary" onClick={searchCandidate}>Search</Link>
            </div>
          </div>

          {/* <button onClick={getUsers}>get data</button> */}
          {users.length === 0 ? <h2 className="text-primary text-center">No such candidate</h2> : users.map((val, key) => {
            return (
              <>
                <div className="col-md-3 my-2">
                  <div className="card">
                    <img src={`https://aajkaa-gsm.aajkaa.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "300px" }} />
                    <div className="card-body">
                      <p className="card-text text-primary fs-5">
                        {val.candidate_firstname}
                      </p>
                      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={getCandidateImformation}>Show Full Profile</button> */}
                      <button type="button" className="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" onClick={() => getCandidateImformation(val.Candidate_id)} >Show Full Profile</button>
                      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" >Show Full Profile</button> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <div className="modal fade modal-xl" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" >
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close mt-3 mx-3 ms-auto"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="modal-body modal-dialog modal-dialog-scrollable">
                  {data.map((val, key) => {
                    return (
                      <>
                        <div className="container">
                          <div className="mx-3">
                            <div
                              className="col-md-12 text-center"
                              id="heading1"
                            >
                              <div className="h4 text-primary" id="heading">
                                <img
                                  src={khanda}
                                  alt=""
                                  style={{ width: "50px", height: "50px" }}
                                  id="image1"
                                />{" "}
                                GLOBAL SIKH MATRIMONY
                                <img
                                  src={khanda}
                                  alt=""
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            </div>
                            <div className="h4 pb-2 mb-4 mt-4 text-primary border-bottom border-primary border-3 text-center">
                              Applicant Profile
                            </div>

                            <form className="row g-3">
                              <div className="">
                                <img src={`https://aajkaa-gsm.aajkaa.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px" }} />
                              </div>
                              <div className="col-md-4">
                                <label className="form-label fs-6">
                                  Applicant's First Name
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.candidate_firstname}
                                />
                              </div>
                              <div className="col-md-4">
                                <label className="form-label fs-6">
                                  Applicant's Middle Name
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.candidate_middlename}
                                />
                              </div>
                              <div className="col-md-4">
                                <label className="form-label fs-6">
                                  Applicant's Last Name
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.candidate_lastname}
                                />
                              </div>

                              <div className="col-md-12">
                                <label className="form-label fs-6">
                                  Applicant's Current Address
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Candidate_Current_Address}
                                />
                              </div>

                              <div className="col-md-3">
                                <label className="form-label fs-6">
                                  Date of Birth
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={new Date(
                                    val.Date_of_Birth
                                  ).toLocaleDateString("en-GB")}
                                />
                              </div>
                              <div className="col-md-3">
                                <label className="form-label fs-6">
                                  Marital Status
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Marital_Status}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom04"
                                  className="form-label fs-6"
                                >
                                  Religion
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Religion}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom04"
                                  className="form-label fs-6"
                                >
                                  Gender
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Gender}
                                />
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="validationCustomUsername" className="form-label fs-6" >Height</label>
                                <div className="input-group has-validation">
                                  <span className="input-group-text" id="inputGroupPrepend" >cm</span>
                                  <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm} />
                                  <div className="invalid-feedback">
                                    Please choose a username.
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-2">
                                <label htmlFor="validationCustomUsername" className="form-label fs-6" >Height</label>
                                <div className="input-group has-validation">
                                  <span className="input-group-text" id="inputGroupPrepend" >Feet</span>
                                  <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" value={feet}/>
                                </div>
                              </div> */}
                              <div className="col-md-4">
                                <label htmlFor="validationCustomUsername" className="form-label fs-6" >Weight</label>
                                <div className="input-group has-validation">
                                  <span className="input-group-text" id="inputGroupPrepend" >Kg</span>
                                  <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Weight} />
                                  <div className="invalid-feedback">
                                    Please choose a username.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="validationCustom01"
                                  className="form-label fs-6"
                                >
                                  Any Disability?
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  placeholder="mental and/or physical"
                                  defaultValue={val.Any_Disability}
                                />
                              </div>

                              <div className="col-md-4">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Mother Tongue
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Mother_Tongue}
                                />
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Languages Known
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Languages_Known}
                                />
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Nationality
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Nationality}
                                />
                              </div>

                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom01"
                                  className="form-label fs-6"
                                >
                                  WhatsApp number
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.WhatsApp_Number}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Email-ID
                                </label>
                                <div className="input-group has-validation">
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                  >
                                    @
                                  </span>
                                  <input
                                    className="form-control fs-5"
                                    type="email"
                                    id="validationCustomUsername"
                                    aria-describedby="inputGroupPrepend"
                                    defaultValue={val.Email_ID}
                                  />
                                  <div className="invalid-feedback">
                                    Please choose a username.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom01"
                                  className="form-label fs-6"
                                >
                                  Education
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Qualification}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom01"
                                  className="form-label fs-6"
                                >
                                  Highest Degree
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Degree}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Applicant's Occupation
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Candidate_Occupation}
                                />
                              </div>

                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Nature Of Work
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Nature_Of_Work}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Currency
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Currency}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustomUsername"
                                  className="form-label fs-6"
                                >
                                  Applicant's Annual Income
                                </label>
                                <input
                                  type="number"
                                  className="form-control fs-5"
                                  id="validationCustomUsername"
                                  aria-describedby="inputGroupPrepend"
                                  defaultValue={val.Candidate_Annual_Income}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="container">
                    <div className="mx-2 mt-3">
                      <div className="h4 pb-2 mb-3 text-primary border-bottom border-primary border-3">
                        Family & Relatives
                      </div>

                      <div className="row text-center mt-4">
                        <div className="col-md-2 d-md-block d-none">
                          <label
                            htmlFor="validationCustomUsername"
                            className="form-label fs-5"
                          >
                            Relation Ship
                          </label>
                        </div>
                        <div className="col-md-3 d-md-block d-none">
                          <label
                            htmlFor="validationCustomUsername"
                            className="form-label fs-5"
                          >
                            Relative's Name
                          </label>
                        </div>
                        <div className="col-md-2 d-md-block d-none">
                          <label
                            htmlFor="validationCustomUsername"
                            className="form-label fs-5"
                          >
                            Age
                          </label>
                        </div>
                        <div className="col-md-2 d-md-block d-none">
                          <label
                            htmlFor="validationCustomUsername"
                            className="form-label fs-5"
                          >
                            Education
                          </label>
                        </div>
                        <div className="col-md-3 d-md-block d-none">
                          <label
                            htmlFor="validationCustomUsername"
                            className="form-label fs-5"
                          >
                            Occupation
                          </label>
                        </div>
                        <hr className="d-md-none" />
                      </div>
                    </div>
                  </div>
                  {relative1Info.map((val, key) => {
                    return (
                      <>
                        <div className="container">
                          <div className="">
                            <form className="row g-3">
                              <div className="row g-3">
                                <div className="col-md-2">
                                  <input
                                    className="form-control fs-5"
                                    type="text"
                                    defaultValue={val.Relationship}
                                  />
                                </div>

                                <div className="col-md-3">
                                  <input
                                    className="form-control fs-5"
                                    type="text"
                                    defaultValue={val.Relative_name}
                                  />
                                </div>

                                <div className="col-md-2">
                                  <input
                                    className="form-control fs-5"
                                    type="text"
                                    defaultValue={val.Relative_age}
                                  />
                                </div>

                                <div className="col-md-2">
                                  <input
                                    className="form-control fs-5"
                                    type="text"
                                    defaultValue={val.Relative_Education}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <input
                                    className="form-control fs-5"
                                    type="text"
                                    defaultValue={val.Relative_Degree}
                                  />
                                </div>

                                <hr className="d-md-none" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {data.map((val, key) => {
                    return (
                      <>
                        <div className="container">
                          <div className="m-3">
                            <form className="row g-3">
                              <div className="col-md-9">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Father's / Family Permanent Address
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.family_permanent_address}
                                />
                              </div>
                              <div className="col-md-3">
                                <label
                                  htmlFor="validationCustomUsername"
                                  className="form-label fs-6"
                                >
                                  Family's Annual Income
                                </label>
                                <div className="input-group has-validation">
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                  >
                                    ₹
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control fs-5"
                                    id="validationCustomUsername"
                                    aria-describedby="inputGroupPrepend"
                                    defaultValue={val.Family_Annual_Income}
                                  />
                                  <div className="invalid-feedback">
                                    Please choose a username.
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="container">
                          <div className="m-3">
                            <form className="row g-3">
                              <div className="h4 pb-2 my-4 text-primary border-bottom border-primary border-3">
                                Gurudwara Details
                              </div>

                              <div className="col-md-12">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Gurudwara Normally visited : Name & Address
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.Gurudwara_Normally_visited}
                                />
                              </div>
                              <div className="col-md-6">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Name of Contact Person at Gurudwara
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.G_Contact_Person}
                                />
                              </div>
                              <div className="col-md-6">
                                <label
                                  htmlFor="validationCustom02"
                                  className="form-label fs-6"
                                >
                                  Phone no. of contact person
                                </label>
                                <input
                                  className="form-control fs-5"
                                  type="text"
                                  defaultValue={val.G_Phone_Number}
                                />
                              </div>
                              {val.additional_information.length == 0 ?
                                <div></div>
                                :
                                <div>
                                  <div div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">
                                    Any Other Information
                                  </div>

                                  <div className="col-md-12">
                                    <p class="form-control" >{val.additional_information}</p>
                                  </div>
                                </div>
                              }
                              <img src={`https://aajkaa-gsm.aajkaa.in/uploads1/${val.fullphoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "400px" }} />
                            </form>
                          </div >
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {/* <button type="button" className="btn btn-primary">Approve Applicant</button */}
                  {data.map((val, key) => {
                    return (
                      <>
                        <Link to="/ApplicantApproval" className="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Approve Applicant</Link>
                        {/* <Link to="/ApplicantApproval" className="btn btn-primary" onClick={() => temp(val.WhatsApp_Number)}>Approve Applicant</Link> */}
                      </>
                    );
                  })}


                </div>
              </div>
            </div>
          </div>
          {data.map((val, key) => {
            return (
              <>
                <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">For Approve</h1>
                        <button type="button" class="btn-close" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body text-primary">
                        <img src={`https://aajkaa-gsm.aajkaa.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "400px" }} />
                        <p className="text-center mt-3">Are you sure to approve this applicant</p>
                      </div>
                      <div class="modal-footer">
                        <Link to="/ApplicantApproval" type="button" class="btn btn-primary" onClick={() => ApproveApplicant(val.Candidate_id, val.WhatsApp_Number,val.Gender,val.candidate_firstname)} target="_parent">Approve Applicant</Link>
                        <button type="button" class="btn btn-secondary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

        </div >
      </div >
      <button className={`btn btn-primary scroll-to-top-button ${isVisible ? 'visible' : ''}`} onClick={scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '20px' }}  ><i class="bi bi-arrow-up fs-4"></i></button>
    </>
  );
}

export default ApplicantApproval;


// <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
//   <div class="modal-dialog modal-dialog-centered">
{/* <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Show a second modal and hide this one with the button below.
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Modal 2</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Hide this modal and show the first with the button below.
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Back to first</button>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Open first modal</button> */}
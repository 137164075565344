import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Modal, Button } from "react-bootstrap";
import heading from "../Photos/heading.png";

function MatchLogin() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const captchaRef = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleCaptchaChange = (token) => {
    console.log("token123", token);
    if (token) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("base url", process.env);
    try {
      const tokenreCAPTCHA = captchaRef.current.getValue();
      captchaRef.current.reset();
      console.log("re", tokenreCAPTCHA);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/login`,
        {
          candidateId: userName,
          password,
          token: tokenreCAPTCHA,
        }
      );
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("candidate_id", response.data.Candidate_id);
      localStorage.setItem("whatsapp_number", response.data.WhatsApp_Number);
      localStorage.setItem("candidate_name", response.data.candidate_firstname);
      const token = JSON.parse(
        atob(localStorage.getItem("token").split(".")[1])
      );
      if ((token.role = "Candidate")) {
        history.push("/match");
      } else if ((token.role = "Approver")) {
        history.push("/ApproverButton");
      }
    } catch (error) {
      // Handle login error
      // alert(error.response.data.message);
      setShowModal(true);
      setModalMessage(error.response.data.message);
      console.error(error);
    }
  };

  const [showForm2, setShowForm2] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const check = (btnclicked) => {
    if (btnclicked === "fp") {
      setShowForm2(true);
      setShowForm1(false);
    } else if (btnclicked === "fmc") {
      setShowForm1(true);
      setShowForm2(false);
    }
  };

  const [applicantNumber, setApplicantNumber] = useState([]);
  const [modalMessage, setModalMessage] = useState([]);

  const getApplicationStatus = () => {
    Axios.post("https://aajkaa-gsm.aajkaa.in/send12", {
      phoneNo: applicantNumber,
    }).then((response) => {
      setShowModal(true);
      setModalMessage("Applicant id Sent To Your Registered Whatsapp Number");
      setRecivedOtp(response.data);
    });
  };

  const [recivedOtp, setRecivedOtp] = useState();
  const [checkOtp, setCheckOtp] = useState();

  const checkotp = () => {
    if (checkOtp == recivedOtp) {
      Axios.post("https://aajkaa-gsm.aajkaa.in/resendapplicantid", {
        applicantNumber: applicantNumber,
      }).then((response) => {
        // setcandidateID(response.data);
        console.log("res", typeof response.data);
        if (response.data == true) {
          setShowModal(true);
          setModalMessage(
            "Candidate id Sent To Your Registered Whatsapp Number"
          );
        } else {
          setShowModal(true);
          setModalMessage("Please Enter Your Registered Whatsapp Number");
        }
      });
    } else {
      setShowModal(true);
      setModalMessage("Otp incorrect");
    }
  };

  // forgot password
  const [candidateId, setCandidateId] = useState([]);
  const getApplicationStatus1 = () => {
    Axios.post("https://aajkaa-gsm.aajkaa.in/forgotpasswordcid", {
      candidateId: candidateId,
    }).then((response) => {
      // setcandidateID(response.data);
      console.log("res", response.data);
      setRecivedOtp1(response.data);
      if (response.data == false) {
        setShowModal(true);
        setModalMessage("Please Enter Your correct Candidate Id");
      } else {
        setShowModal(true);
        setModalMessage("Otp is Sent To Your Whatsapp Number");
      }
    });
  };

  const [checkOtp1, setCheckOtp1] = useState();
  const [recivedOtp1, setRecivedOtp1] = useState();

  const checkotp1 = () => {
    if (checkOtp1 == recivedOtp1) {
      Axios.post(
        "https://aajkaa-gsm.aajkaa.in/forgotpasswordcidupdatepassword",
        {
          candidateId: candidateId,
        }
      ).then((response) => {});
      setShowModal(true);
      setModalMessage(
        "Your new password is sent to your register Whatsapp number"
      );
    } else {
      setShowModal(true);
      setModalMessage("Otp incorrect");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div id="loginmargin">
      <div className="mx-4 p-3 text-primary">
        <img src={heading} alt="" class="col-md-5 text-center img-fluid" />
      </div>
      <div className="mx-3">
        <form>
          <div className="col-md-5 my-3">
            <label className="form-label">Candidate-ID</label>
            <input
              type="number"
              className="form-control"
              onChange={handleUserNameChange}
              value={userName}
            />
          </div>
          <div className="col-md-5 my-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              onChange={handlePasswordChange}
              value={password}
            />
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY_NEW}
            ref={captchaRef}
            onChange={handleCaptchaChange}
          />
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!captchaVerified}
            style={{ marginTop: "10px" }}
          >
            Login
          </button>
          <div className="col-md-5 my-3">
            <Link
              className="text-danger mx-1 text-decoration-none"
              onClick={() => check("fp")}
            >
              Forgot Password
            </Link>
            {/* <span class="mx-4 border border-2 border-primary d-md-none" style={{ height: "10px" }}></span> */}
            <span
              class="mx-4 border border-2 border-primary d-none d-md-inline"
              style={{ height: "10px" }}
            ></span>
            <br className="d-md-none" />
            <Link
              className="text-danger mx-1 text-decoration-none"
              onClick={() => check("fmc")}
            >
              Forgot My Candidate-ID
            </Link>
          </div>
        </form>

        {showForm2 && (
          <form className="col-md-12 my-3 mt-4">
            <div className="row g-3">
              <h4 className="text-primary">Forgot Password</h4>

              <div className="col-md-3">
                <input
                  className="form-control"
                  id="input"
                  type="number"
                  placeholder="Enter Candidate-ID"
                  onChange={(event) => {
                    setCandidateId(event.target.value);
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-1">
                <Link
                  to="/login"
                  className="btn btn-primary"
                  onClick={getApplicationStatus1}
                >
                  Get OTP
                </Link>
              </div>
              <div className="col-8"></div>
              <div className="col-md-3">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter OTP"
                  onChange={(event) => {
                    setCheckOtp1(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3 mb-4">
                <Link
                  to="/login"
                  className="btn btn-primary"
                  onClick={checkotp1}
                >
                  Verify OTP
                </Link>
              </div>
            </div>
          </form>
        )}

        {showForm1 && (
          <form className="col-md-12 my-3 mt-4">
            <div className="row g-3">
              <h4 className="text-primary">Forgot My Candidate-ID</h4>

              <div className="col-md-3">
                <input
                  className="form-control"
                  id="input"
                  type="number"
                  placeholder="Enter Verified Whatsapp Number"
                  onChange={(event) => {
                    setApplicantNumber(event.target.value);
                  }}
                  autocomplete="off"
                />
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={getApplicationStatus}
                >
                  Get OTP
                </button>
              </div>
              <div className="col-8"></div>
              <div className="col-md-3">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter OTP"
                  onChange={(event) => {
                    setCheckOtp(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3 mb-4">
                <Link
                  to="/login"
                  className="btn btn-primary"
                  onClick={checkotp}
                >
                  Verify OTP
                </Link>
              </div>
            </div>
          </form>
        )}

        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>GSM says</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal} autoFocus>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default MatchLogin;
